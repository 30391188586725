var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "auto" } }, [
            _c("h1", [_vm._v("PMGateway Dashboard")]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-alert",
                { attrs: { variant: "info", show: this.pendingUserCount > 0 } },
                [
                  _c(
                    "b-link",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.pending-user-collapse",
                          modifiers: { "pending-user-collapse": true },
                        },
                      ],
                      staticClass: "stretched-link text-secondary",
                    },
                    [
                      _c("fa-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "exclamation-circle" },
                      }),
                      _vm._v(
                        " There are " +
                          _vm._s(this.pendingUserCount) +
                          " pending users "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              this.pendingUserCount > 0
                ? _c(
                    "b-collapse",
                    { attrs: { id: "pending-user-collapse" } },
                    [
                      _c(
                        "b-table-simple",
                        { attrs: { responsive: "" } },
                        [
                          _c(
                            "b-thead",
                            [
                              _c(
                                "b-tr",
                                [
                                  _c("b-td", [_vm._v("Name")]),
                                  _c("b-td", [_vm._v("Email")]),
                                  _c("b-td", [_vm._v("Company")]),
                                  _c("b-td", [_vm._v("Phone")]),
                                  _c("b-td"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-tbody",
                            _vm._l(_vm.pendingUsers, function (user) {
                              return _c(
                                "b-tr",
                                { key: user.id },
                                [
                                  _c("b-td", [
                                    _vm._v(" " + _vm._s(user.name) + " "),
                                  ]),
                                  _c("b-td", [
                                    _vm._v(" " + _vm._s(user.email) + " "),
                                  ]),
                                  _c("b-td", [
                                    _vm._v(" " + _vm._s(user.company) + " "),
                                  ]),
                                  _c("b-td", [
                                    _vm._v(" " + _vm._s(user.phone) + " "),
                                  ]),
                                  _c(
                                    "b-td",
                                    [
                                      _c(
                                        "b-btn-group",
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              attrs: { variant: "success" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.approveUser(
                                                    user.id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Approve")]
                                          ),
                                          _c(
                                            "b-btn",
                                            {
                                              attrs: {
                                                variant: "danger",
                                                disabled: "",
                                              },
                                            },
                                            [_vm._v("Delete")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c("b-col", { attrs: { cols: "12", sm: "6", md: "4" } }, [
            _c(
              "div",
              { staticClass: "dash-card" },
              [
                _c(
                  "b-card",
                  { attrs: { title: "Total Users", "bg-variant": "light" } },
                  [
                    _c("h1", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(this.totalUsers)),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("b-col", { attrs: { cols: "12", sm: "6", md: "4" } }, [
            _c(
              "div",
              { staticClass: "dash-card" },
              [
                _c(
                  "b-card",
                  { attrs: { title: "Total Loggers", "bg-variant": "light" } },
                  [
                    _c("h1", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(this.totalLoggers)),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("b-col", { attrs: { cols: "12", sm: "6", md: "4" } }, [
            _c(
              "div",
              { staticClass: "dash-card" },
              [
                _c(
                  "b-card",
                  { attrs: { title: "Active Loggers", "bg-variant": "light" } },
                  [
                    _c("h1", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(this.activeLoggers)),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("b-col", { attrs: { cols: "12", sm: "6", md: "4" } }, [
            _c(
              "div",
              { staticClass: "dash-card" },
              [
                _c(
                  "b-card",
                  { attrs: { title: "User Settings", "bg-variant": "light" } },
                  [
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "b-link",
                          {
                            staticClass: "stretched-link",
                            attrs: { to: "/oem/user" },
                          },
                          [
                            _c("fa-icon", {
                              attrs: { icon: "user-cog", size: "5x" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("b-col", { attrs: { cols: "12", sm: "6", md: "4" } }, [
            _c(
              "div",
              { staticClass: "dash-card" },
              [
                _c(
                  "b-card",
                  {
                    attrs: { title: "Logger Settings", "bg-variant": "light" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "b-link",
                          {
                            staticClass: "stretched-link",
                            attrs: { to: "/oem/logger" },
                          },
                          [
                            _c("fa-icon", {
                              attrs: { icon: "toolbox", size: "5x" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("b-col", { attrs: { cols: "12", sm: "6", md: "4" } }, [
            _c(
              "div",
              { staticClass: "dash-card" },
              [
                _c(
                  "b-card",
                  {
                    attrs: { title: "System Settings", "bg-variant": "light" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "b-link",
                          {
                            staticClass: "stretched-link",
                            attrs: { to: "/oem/system" },
                          },
                          [
                            _c("fa-icon", {
                              attrs: { icon: "cog", size: "5x" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }